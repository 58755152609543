<div class="header">
	<h1>Specialties</h1>
</div>
<table *ngIf="specialty">
	<thead>
		<td>ID</td>
		<td>Name</td>
	</thead>
	<tbody>
		<tr>
			<td>
				<a [routerLink]="['/specialties', specialty.specialtyId]">
					{{ specialty.specialtyId }}
				</a>
			</td>
			<td>{{ specialty.name }}</td>
		</tr>
	</tbody>
</table>
<table>
	<thead>
		<td>ID</td>
		<td>Place of Service Code</td>
		<td>Total</td>
		<td>Total Copay</td>
		<td>Total Coinsurance</td>
		<td>Created</td>
	</thead>
	<tbody>
		<tr *ngFor="let model of models">
			<td>{{ model.modelId }}</td>
			<td>{{ model.placeOfServiceCode }}</td>
			<td>{{ model.total | number }}</td>
			<td>{{ model.totalCopay | number }}</td>
			<td>{{ model.totalCoinsurance | number }}</td>
			<td>{{ model.created | date }}</td>
		</tr>
	</tbody>
</table>
<table>
	<thead>
		<td>Code</td>
	</thead>
	<tbody>
		<tr *ngFor="let code of codes">
			<td>{{ code.code }}</td>
		</tr>
	</tbody>
</table>
