export const STATES = [
    "AK",
    "AL",
    "AR",
    "AS",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "GU",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MP",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TT",
    "TX",
    "UT",
    "VA",
    "VI",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY"
]

export const SERVICE_TYPE_CODES = [
    {code: "1", name: "Medical Care"},
    {code: "2", name: "Surgical"},
    {code: "3", name: "Consultation"},
    {code: "4", name: "Diagnostic X-Ray"},
    {code: "5", name: "Diagnostic Lab"},
    {code: "6", name: "Radiation Therapy"},
    {code: "7", name: "Anesthesia"},
    {code: "8", name: "Surgical Assistance"},
    {code: "9", name: "Other Medical"},
    {code: "10", name: "Blood Charges"},
    {code: "11", name: "Used Durable Medical Equipment"},
    {code: "12", name: "Durable Medical Equipment Purchase"},
    {code: "13", name: "Ambulatory Service Center Facility"},
    {code: "14", name: "Renal Supplies in the Home"},
    {code: "15", name: "Alternate Method Dialysis"},
    {code: "16", name: "Chronic Renal Disease (CRD) Equipment"},
    {code: "17", name: "Pre-Admission Testing"},
    {code: "18", name: "Durable Medical Equipment Rental"},
    {code: "19", name: "Pneumonia Vaccine"},
    {code: "20", name: "Second Surgical Opinion"},
    {code: "21", name: "Third Surgical Opinion"},
    {code: "22", name: "Social Work"},
    {code: "23", name: "Diagnostic Dental"},
    {code: "24", name: "Periodontics"},
    {code: "25", name: "Restorative"},
    {code: "26", name: "Endodontics"},
    {code: "27", name: "Maxillofacial Prosthetics"},
    {code: "28", name: "Adjunctive Dental Services"},
    {code: "30", name: "Health Benefit Plan Coverage"},
    {code: "31", name: "Benefit Disclaimer"},
    {code: "32", name: "Plan Waiting Period"},
    {code: "33", name: "Chiropractic"},
    {code: "34", name: "Chiropractic Office Visits"},
    {code: "35", name: "Dental Care"},
    {code: "36", name: "Dental Crowns"},
    {code: "37", name: "Dental Accident"},
    {code: "38", name: "Orthodontics"},
    {code: "39", name: "Prosthodontics"},
    {code: "40", name: "Oral Surgery"},
    {code: "41", name: "Routine (Preventive) Dental"},
    {code: "42", name: "Home Health Care"},
    {code: "43", name: "Home Health Prescriptions"},
    {code: "44", name: "Home Health Visits"},
    {code: "45", name: "Hospice"},
    {code: "46", name: "Respite Care"},
    {code: "47", name: "Hospital"},
    {code: "48", name: "Hospital, Inpatient"},
    {code: "49", name: "Hospital, Room and Board"},
    {code: "50", name: "Hospital, Outpatient"},
    {code: "51", name: "Hospital, Emergency Accident"},
    {code: "52", name: "Hospital, Emergency Medical"},
    {code: "53", name: "Hospital, Ambulatory Surgical"},
    {code: "54", name: "Long Term Care"},
    {code: "55", name: "Major Medical"},
    {code: "56", name: "Medically Related Transportation"},
    {code: "57", name: "Air Transportation"},
    {code: "58", name: "Cabulance"},
    {code: "59", name: "Licensed Ambulance"},
    {code: "60", name: "General Benefits"},
    {code: "61", name: "In-vitro Fertilization"},
    {code: "62", name: "MRI/CAT Scan"},
    {code: "63", name: "Donor Procedures"},
    {code: "64", name: "Acupuncture"},
    {code: "65", name: "Newborn Care"},
    {code: "66", name: "Pathology"},
    {code: "67", name: "Smoking Cessation"},
    {code: "68", name: "Well Baby Care"},
    {code: "69", name: "Maternity"},
    {code: "70", name: "Transplants"},
    {code: "71", name: "Audiology Exam"},
    {code: "72", name: "Inhalation Therapy"},
    {code: "73", name: "Diagnostic Medical"},
    {code: "74", name: "Private Duty Nursing"},
    {code: "75", name: "Prosthetic Device"},
    {code: "76", name: "Dialysis"},
    {code: "77", name: "Otological Exam"},
    {code: "78", name: "Chemotherapy"},
    {code: "79", name: "Allergy Testing"},
    {code: "80", name: "Immunizations"},
    {code: "81", name: "Routine Physical"},
    {code: "82", name: "Family Planning"},
    {code: "83", name: "Infertility"},
    {code: "84", name: "Abortion"},
    {code: "85", name: "AIDS"},
    {code: "86", name: "Emergency Services"},
    {code: "87", name: "Cancer"},
    {code: "88", name: "Pharmacy"},
    {code: "89", name: "Free Standing Prescription Drug"},
    {code: "90", name: "Mail Order Prescription Drug"},
    {code: "91", name: "Brand Name Prescription Drug"},
    {code: "92", name: "Generic Prescription Drug"},
    {code: "93", name: "Podiatry"},
    {code: "94", name: "Podiatry, Office Visits"},
    {code: "95", name: "Podiatry, Nursing Home Visits"},
    {code: "96", name: "Professional (Physician)"},
    {code: "97", name: "Anesthesiologist"},
    {code: "98", name: "Professional (Physician) Visit, Office"},
    {code: "99", name: "Professional (Physician) Visit, Inpatient"},
    {code: "A0", name: "Professional (Physician) Visit, Outpatient"},
    {code: "A1", name: "Professional (Physician) Visit, Nursing Home"},
    {code: "A2", name: "Professional (Physician) Visit, Skilled Nursing Facility"},
    {code: "A3", name: "Professional (Physician) Visit, Home"},
    {code: "A4", name: "Psychiatric"},
    {code: "A5", name: "Psychiatric, Room and Board"},
    {code: "A6", name: "Psychotherapy"},
    {code: "A7", name: "Psychiatric, Inpatient"},
    {code: "A8", name: "Psychiatric, Outpatient"},
    {code: "A9", name: "Rehabilitation"},
    {code: "AA", name: "Rehabilitation, Room and Board"},
    {code: "AB", name: "Rehabilitation, Inpatient"},
    {code: "AC", name: "Rehabilitation, Outpatient"},
    {code: "AD", name: "Occupational Therapy"},
    {code: "AE", name: "Physical Medicine"},
    {code: "AF", name: "Speech Therapy"},
    {code: "AG", name: "Skilled Nursing Care"},
    {code: "AH", name: "Skilled Nursing Care, Room and Board"},
    {code: "AI", name: "Substance Abuse"},
    {code: "AJ", name: "Alcoholism"},
    {code: "AK", name: "Drug Addiction"},
    {code: "AL", name: "Vision (Optometry)"},
    {code: "AM", name: "Frames"},
    {code: "AN", name: "Routine Exam"},
    {code: "AO", name: "Lenses"},
    {code: "AP", name: "Routine Eye Exam"},
    {code: "AQ", name: "Nonmedically Necessary Physical"},
    {code: "AR", name: "Experimental Drug Therapy"},
    {code: "B", name: "Non-escrow or Non-impound Service"},
    {code: "B1", name: "Burn Care"},
    {code: "B2", name: "Brand Name Prescription Drug, Formulary"},
    {code: "B3", name: "Brand Name Prescription Drug, Non-Formulary"},
    {code: "BA", name: "Independent Medical Evaluation"},
    {code: "BB", name: "Partial Hospitalization (Psychiatric)"},
    {code: "BC", name: "Day Care (Psychiatric)"},
    {code: "BD", name: "Cognitive Therapy"},
    {code: "BE", name: "Massage Therapy"},
    {code: "BF", name: "Pulmonary Rehabilitation"},
    {code: "BG", name: "Cardiac Rehabilitation"},
    {code: "BH", name: "Pediatric"},
    {code: "BI", name: "Nursery"},
    {code: "BJ", name: "Skin"},
    {code: "BK", name: "Orthopedic"},
    {code: "BL", name: "Cardiac"},
    {code: "BM", name: "Lymphatic"},
    {code: "BN", name: "Gastrointestinal"},
    {code: "BP", name: "Endocrine"},
    {code: "BQ", name: "Neurology"},
    {code: "BR", name: "Eye"},
    {code: "BS", name: "Invasive Procedures"},
    {code: "BT", name: "Gynecological"},
    {code: "BU", name: "Obstetrical"},
    {code: "BV", name: "Obstetrical/Gynecological"},
    {code: "BW", name: "Mail Order Prescription Drug: Brand Name"},
    {code: "BX", name: "Mail Order Prescription Drug: Generic"},
    {code: "BY", name: "Physician Visit, Office: Sick"},
    {code: "BZ", name: "Physician Visit, Office: Well"},
    {code: "C", name: "Escrow or Impound Service"},
    {code: "C1", name: "Coronary Care"},
    {code: "CA", name: "Private Duty Nursing, Inpatient"},
    {code: "CB", name: "Private Duty Nursing, Home"},
    {code: "CC", name: "Surgical Benefits, Professional (Physician)"},
    {code: "CD", name: "Surgical Benefits, Facility"},
    {code: "CE", name: "Mental Health Provider, Inpatient"},
    {code: "CF", name: "Mental Health Provider, Outpatient"},
    {code: "CG", name: "Mental Health Facility, Inpatient"},
    {code: "CH", name: "Mental Health Facility, Outpatient"},
    {code: "CI", name: "Substance Abuse Facility, Inpatient"},
    {code: "CJ", name: "Substance Abuse Facility, Outpatient"},
    {code: "CK", name: "Screening X-ray"},
    {code: "CL", name: "Screening laboratory"},
    {code: "CM", name: "Mammogram, High Risk Patient"},
    {code: "CN", name: "Mammogram, Low Risk Patient"},
    {code: "CO", name: "Flu Vaccination"},
    {code: "CP", name: "Eyewear and Eyewear Accessories"},
    {code: "CQ", name: "Case Management"},
    {code: "DG", name: "Dermatology"},
    {code: "DM", name: "Durable Medical Equipment"},
    {code: "DS", name: "Diabetic Supplies"},
    {code: "GF", name: "Generic Prescription Drug, Formulary"},
    {code: "GN", name: "Generic Prescription Drug, Non-Formulary"},
    {code: "GY", name: "Allergy"},
    {code: "IC", name: "Intensive Care"},
    {code: "MH", name: "Mental Health"},
    {code: "NI", name: "Neonatal Intensive Care"},
    {code: "ON", name: "Oncology"},
    {code: "PE", name: "Positron Emission Tomography (PET) Scan"},
    {code: "PT", name: "Physical Therapy"},
    {code: "PU", name: "Pulmonary"},
    {code: "RN", name: "Renal"},
    {code: "RT", name: "Residential Psychiatric Treatment"},
    {code: "TC", name: "Transitional Care"},
    {code: "TN", name: "Transitional Nursery Care"},
    {code: "UC", name: "Urgent Care"}
]
