import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { DiscoveryRequest } from "../@shared/models/discoveryRequest"

@Component({
  selector: 'app-discovery',
  templateUrl: './discovery.component.html'
})
export class DiscoveryComponent {

  private discoveryRequests: DiscoveryRequest[] = []

  filterTimestamp: string = "24-hours"
  filterStatus: string = ""

  statusMap = {
    0: "Created",
    1: "Pending",
    2: "Complete",
    3: "In Progress",
    4: "Error"
  }

  get completeCount(): number {
    return this.filteredDiscoveryRequests.filter(discoveryRequest => {
      return discoveryRequest.status == 2
    }).length
  }

  get successfulPercentage(): number {
    if (!this.filteredDiscoveryRequests.length) {
      return null
    }

    const successful = this.filteredDiscoveryRequests.filter(discoveryRequest => {
      return discoveryRequest.status == 2 &&
        discoveryRequest.memberId != null
    }).length

    return successful / this.filteredDiscoveryRequests.length
  }

  get pendingCount(): number {
    return this.filteredDiscoveryRequests.filter(discoveryRequest => {
      return discoveryRequest.status == 1
    }).length
  }

  get averageSeconds(): number {
    if (!this.filteredDiscoveryRequests.length) {
      return null
    }

    const total = this.filteredDiscoveryRequests.reduce((acc, discoveryRequest) => {
      return acc + discoveryRequest.seconds
    }, 0)

    return Math.round(total / this.filteredDiscoveryRequests.length * 100) / 100
  }

  get filteredDiscoveryRequests(): DiscoveryRequest[] {
    return this.discoveryRequests.filter(discoveryRequest => {
      let filterTimestampValue = true
      if (this.filterTimestamp == "") {
        filterTimestampValue = true
      } else if (this.filterTimestamp == "24-hours") {
        const date = new Date(discoveryRequest.timestamp).getTime()
        const interval = new Date().getTime() - (1 * 24 * 60 * 60 * 1000)
        filterTimestampValue = date > interval
      } else if (this.filterTimestamp == "12-hours") {
        const date = new Date(discoveryRequest.timestamp).getTime()
        const interval = new Date().getTime() - (1 * 12 * 60 * 60 * 1000)
        filterTimestampValue = date > interval
      } else if (this.filterTimestamp == "1-hour") {
        const date = new Date(discoveryRequest.timestamp).getTime()
        const interval = new Date().getTime() - (1 * 1 * 60 * 60 * 1000)
        filterTimestampValue = date > interval
      } else {
        filterTimestampValue = false
      }
      let filterStatusValue = true
      if (this.filterStatus == "") {
        filterStatusValue = true
      } else {
        const status = parseInt(this.filterStatus)
        filterStatusValue = status == discoveryRequest.status
      }
      return filterTimestampValue && filterStatusValue
    })
  }

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Discovery")
    this.getDiscoveryRequests()
  }

  async getDiscoveryRequests() {
    this.discoveryRequests = await this.adminService.getDiscoveryRequests()
  }
}
