import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PayersComponent } from './payers/payers.component';
import { PayerComponent } from './payer/payer.component';
import { AuthGuard } from './@shared/guards/auth.guard';
import { VerificationComponent } from "./verification/verification.component"
import { CreatePayerComponent } from "./create-payer/create-payer.component"
import { EditPayerComponent } from "./edit-payer/edit-payer.component"
import { RelatedEntitiesComponent } from './related-entities/related-entities.component';
import { CustomersComponent } from "./customers/customers.component"
import { CustomerComponent } from "./customer/customer.component"
import { CreateCustomerComponent } from "./create-customer/create-customer.component"
import { DiscoveryComponent } from "./discovery/discovery.component"
import { DiscoveryDetailComponent } from "./discovery-detail/discovery-detail.component"
import { AuthenticatorComponent } from "./authenticator/authenticator.component"
import { TotpComponent } from "./totp/totp.component"
import { TaxonomyCodeComponent } from "./taxonomy-code/taxonomy-code.component"
import { TaxonomyCodeGroupsComponent } from "./taxonomy-code-groups/taxonomy-code-groups.component"
import { SpecialtiesComponent } from "./specialties/specialties.component"
import { SpecialtyComponent } from "./specialty/specialty.component"

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'authenticator',
    component: AuthenticatorComponent
  },
  {
    path: 'totp',
    component: TotpComponent
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'verification/:id',
        component: VerificationComponent
      },
      {
        path: 'payers',
        component: PayersComponent
      },
      {
        path: 'discovery',
        component: DiscoveryComponent
      },
      {
        path: 'discovery/:id',
        component: DiscoveryDetailComponent
      },
      {
        path: 'related-entities',
        component: RelatedEntitiesComponent
      },
      {
        path: 'create-payer',
        component: CreatePayerComponent
      },
      {
        path: 'payers/:id',
        component: PayerComponent
      },
      {
        path: 'payers/:id/taxonomy-codes/:taxonomyCode',
        component: TaxonomyCodeComponent
      },
      {
        path: 'payers/:id/edit',
        component: EditPayerComponent
      },
      {
        path: 'customers',
        component: CustomersComponent
      },
      {
        path: 'customers/:id',
        component: CustomerComponent
      },
      {
        path: 'create-customer',
        component: CreateCustomerComponent
      },
      {
        path: 'groups',
        component: TaxonomyCodeGroupsComponent
      },
      {
        path: 'specialties',
        component: SpecialtiesComponent
      },
      {
        path: 'specialties/:id',
        component: SpecialtyComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
