import { NetworkCoverage } from "./network-coverage"
import { VerificationCarveOut } from "./verification-carve-out"
import { BenefitsRelatedEntity } from "./benefits-related-entity"
import { Event } from "./event"

export class Verification {

	constructor(
		public verificationId: string,
		public memberId: string,
		public payerId: string,
		public sandbox: boolean,
		public status: string,
		public coverageStartDate: string,
		public coverageEndDate: string,
		public planName: string,
		public benefits: NetworkCoverage[],
		public timestamp: string,
		public payerName: string,
		public patientId: string,
		public phoneNumber: string,
		public changeHealthcarePayerId: string,
		public customerName: string,
		public customerId: string,
		public dateOfBirth: string,
		public firstName: string,
		public lastName: string,
		public state: string,
		public npi: string,
		public organizationName: string,
		public updated: string,
		public resolutionMethod: string,
		public groupNumber: string,
		public planNumber: string,
		public taxId: string,
		public carveOut: VerificationCarveOut | null,
		public adminCognitoId: string | null,
		public adminFirstName: string | null,
		public adminLastName: string | null,
		public benefitsRelatedEntity: BenefitsRelatedEntity | null,
		public serviceTypeCode: string | null,
		public networkType: string | null,
		public taxonomyCode: string | null,
		public taxonomyCodeGroup: string|null,
		public taxonomy: string|null,
		public providerAddress: string | null,
		public patientAddress: string | null,
		public webhookId: string|null,
		public events: Event[],
		public placeOfServiceCode: string|null,
		public isSelected: boolean) { }

	get coveragePeriod(): string | null {
		if (this.coverageStartDate && this.coverageEndDate) {
			return `${this.coverageStartDate} to ${this.coverageEndDate}`
		} else if (this.coverageStartDate) {
			return `From ${this.coverageStartDate}`
		} else {
			return null
		}
	}

	get fullName(): string {
		if (!this.firstName) {
			return null
		} else if (!this.lastName) {
			return this.firstName
		}

		return `${this.firstName} ${this.lastName}`
	}

	get adminFullName(): string {
		if (!this.adminFirstName) {
			return null
		} else if (!this.adminLastName) {
			return this.adminFirstName
		}

		return `${this.adminFirstName} ${this.adminLastName}`
	}

	get adminInitials(): string {
		return this.adminFullName?.split(" ").map(str => str[0]).join("")
	}

	get relatedEntity(): string|null {
		if (this.benefitsRelatedEntity == null) {
			return null
		}

		return this.benefitsRelatedEntity.name
	}

	get resolutionTime(): number {
		if (!this.timestamp) {
			return 0
		}

		const created = new Date(this.timestamp)

		if (this.updated) {
			const updated = new Date(this.updated)
			return (updated.getTime() - created.getTime()) / 1000 / 60 / 60
		}

		return (new Date().getTime() - created.getTime()) / 1000 / 60 / 60
	}

	get serviceLevelAgreementWarning(): boolean {
		const created = new Date(this.timestamp)
		const date = new Date()
		const diff = Math.abs(date.getTime() - created.getTime()) / 3600000
		return diff > 22
	}

	static fromJson(data: any): Verification {
		return new Verification(
			data.verificationId,
			data.memberId,
			data.payerId,
			data.sandbox,
			data.status,
			data.coverageStartDate,
			data.coverageEndDate,
			data.planName,
			(data.benefits || []).map((benefit: any) => {
				return NetworkCoverage.fromJson(benefit)
			}),
			data.timestamp,
			data.payerName,
			data.patientId,
			data.phoneNumber,
			data.changeHealthcarePayerId,
			data.customerName,
			data.customerId,
			data.dateOfBirth,
			data.firstName,
			data.lastName,
			data.state,
			data.npi,
			data.organizationName,
			data.updated,
			data.resolutionMethod,
			data.groupNumber,
			data.planNumber,
			data.taxId,
			VerificationCarveOut.fromJson(data.carveOut),
			data.adminCognitoId,
			data.adminFirstName,
			data.adminLastName,
			BenefitsRelatedEntity.fromJson(data),
			data.serviceTypeCode,
			data.networkType,
			data.taxonomyCode,
			data.taxonomyCodeGroup,
			data.taxonomy,
			data.providerAddress,
			data.patientAddress,
			data.webhookId,
			data.events.map((event: any) => {
				return Event.fromJson(event)
			}),
			data.placeOfService,
			false)
	}
}
