import { Component } from '@angular/core'
import { PayerAdminAssignee } from '../@shared/models/payer-admin-assignee'
import { AdminService } from '../@shared/services/admin.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Payer } from "../@shared/models/payer"
import { AdminUser } from "../@shared/models/admin-user"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-assignees',
  templateUrl: './assignees.component.html'
})
export class AssigneesComponent {

  form: UntypedFormGroup
  payerAdminAssignees: PayerAdminAssignee[] = []
  showAssignPayerForm = false
  payers: Payer[] = []
  adminUsers: AdminUser[] = []

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title
  ) { }

  async ngOnInit() {
    this.titleService.setTitle("Assignees")

    this.form = this.formBuilder.group({
      adminCognitoId: ["", [Validators.required]],
      payerId: ["", [Validators.required]],
    })

    this.payers = await this.adminService.getPayers()

    const data = await this.adminService.getData()

    this.adminUsers = data.adminUsers

    this.getPayersAssignees()
  }

  toggleAssignPayerForm() {
    this.showAssignPayerForm = !this.showAssignPayerForm
  }

  handleAssignmentCreated() {
    this.form.reset()
    this.toggleAssignPayerForm()
    this.getPayersAssignees()
  }

  async getPayersAssignees() {
    this.payerAdminAssignees = await this.adminService.getPayersAssignees()
  }

  async deleteAssignee(payerId: string) {
    const confirmed = confirm(`Are you sure you want to delete this assignee?`)

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deletePayerAssignee(payerId)
      await this.getPayersAssignees()
    } catch (error) {
      alert("Failed to delete assignee. Please try again.")
    }
  }

  async onSubmit() {
    const payerId = this.form.value.payerId
    const adminCognitoId = this.form.value.adminCognitoId

    if (!payerId || !adminCognitoId) {
      return
    }

    try {
      await this.adminService.setPayerAssignee(adminCognitoId, payerId)
      this.handleAssignmentCreated()
    } catch (error) {
      alert("Failed to assign payer. Please try again.")
    }
  }
}
