export class PayerDefault {

	constructor(
		public payerId: string,
		public category: string,
		public innPriorAuthRequired: number|null,
		public oonPriorAuthRequired: number|null,
		public innPriorAuthRequiredNotes: string|null,
		public oonPriorAuthRequiredNotes: string|null) {}

	static fromJson(data: any): PayerDefault {
		return new PayerDefault(
			data.payerId,
			data.category,
			data.innPriorAuthRequired == undefined ? null : data.innPriorAuthRequired,
			data.oonPriorAuthRequired == undefined ? null : data.oonPriorAuthRequired,
			data.innPriorAuthRequiredNotes,
			data.oonPriorAuthRequiredNotes)
	}
}
