<div class="header">
	<h1>Payer Routing</h1>
</div>
<div class="filters">
	<select [(ngModel)]="typeFilter">
		<option value="">
			All Types
		</option>
		<option *ngFor="let type of types" [value]="type">
			{{ type }}
		</option>
	</select>
	<select [(ngModel)]="payerFilter">
		<option value="">
			All Payers
		</option>
		<option *ngFor="let payer of payers" [value]="payer.payerId">
			{{ payer.payerName }}
		</option>
	</select>
</div>
<div>
	<form [formGroup]="form" (ngSubmit)="didSubmit()">
		<select formControlName="option">
			<option [value]="null">Select an option</option>
			<option [value]="'benefits-related-entity'">Benefits Related Entity</option>
			<option [value]="'member-id'">Member ID Pattern</option>
		</select>
		<div *ngIf="form.value.option == 'member-id'">
			<label>Pattern</label>
			<input type="text" formControlName="pattern" placeholder="Member ID pattern" />
		</div>
		<div *ngIf="form.value.option == 'benefits-related-entity'">
			<label>Name</label>
			<input type="text" formControlName="name" placeholder="Related entity name" />
			<label>Phone Number</label>
			<input type="text" formControlName="phoneNumber" placeholder="Optional related entity phone number" />
		</div>
		<div *ngIf="form.value.option != null">
			<label>Type</label>
			<select formControlName="type" required>
				<option [value]="null">Select a type</option>
				<option [value]="'internal'">Internal</option>
				<option [value]="'um'">Utilization Management</option>
				<option [value]="'carve-out'">Carve Out</option>
				<option [value]="'payer'">Payer</option>
				<option [value]="'mco'">MCO</option>
			</select>
			<div *ngIf="form.value.type == 'payer' || form.value.type == 'um' || form.value.type == 'carve-out' || form.value.type == 'mco'">
				<label>Payer</label>
				<select formControlName="payerId">
					<option value="null">Select a payer</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }}</option>
				</select>
			</div>
		</div>
		<button>Submit</button>
	</form>
	<table>
		<thead>
			<td>Benefits Related Entity</td>
			<td>Benefits Related Entity Phone Number</td>
			<td>Member ID Pattern</td>
			<td>Payer</td>
			<td>Type</td>
			<td></td>
		</thead>
		<tbody *ngFor="let entity of filteredRelatedEntities">
			<tr>
				<td>{{ entity.name }}</td>
				<td>{{ entity.phoneNumber }}</td>
				<td>{{ entity.pattern }}</td>
				<td><a [routerLink]="['/payers', entity.payerId]">{{ entity.payerName }}</a></td>
				<td>{{ entity.type }}</td>
				<td><a class="button" (click)="didClickDeleteRelatedEntity(entity)">Delete</a></td>
			</tr>
		</tbody>
	</table>
	<table class="overflow">
		<thead>
			<td>Type</td>
			<td>Description</td>
		</thead>
		<tbody>
			<tr>
				<td>internal</td>
				<td>An internal department at the payer. A related entity flagged as internal will be ignored.</td>
			</tr>
			<tr>
				<td>um</td>
				<td>A Utilization Management organization. Holds no benefits information. A related entity flagged as um will be resolved as complete.eligible.</td>
			</tr>
			<tr>
				<td>carve-out</td>
				<td>A carve out payer. Holds benefits information, the request must be routed to this payer before resolving the verification. A related entity flagged as payer must include a payer ID for routing purposes.</td>
			</tr>
			<tr>
				<td>payer</td>
				<td>A payer to re-route a request to based on a member ID pattern.</td>
			</tr>
			<tr>
				<td>mco</td>
				<td>A Managed Care Organization. Holds no benefits information. A related entity flagged as mco will be resolved as complete.eligible.</td>
			</tr>
		</tbody>
	</table>
</div>