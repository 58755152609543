import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { Verification } from "../models/verification"
import { VerificationsResponse } from "../models/verifications-response"
import { Payer } from "../models/payer"
import { PayerDefault } from "../models/payer-default"
import { AdminUser } from '../models/admin-user';
import { Customer } from '../models/customer';
import { PayerAdminAssignee } from '../models/payer-admin-assignee';
import { NetworkType } from '../models/network-type';
import { RelatedEntity } from "../models/related-entity"

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  cache: {
    [url: string]: any
  } = {}

  constructor(
    private networkService: NetworkService
  ) {}

  async retryPendingVerifications(payerId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/verifications`, {}).pipe(
        map((res: any) => {
          return res.verifications
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async retryPendingVerification(verificationId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/verifications/${verificationId}`, {}).pipe(
        map((res: any) => {
          return res.verification
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getData(): Promise<{
    taxonomyCodeGroups: any[],
    networkTypes: any[],
    adminUsers: any[]
  }> {
    if (this.cache[`admin/data`]) {
      return this.cache[`admin/data`]
    }
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/data`).pipe(
        map((res: any) => {
          const _res = {
            taxonomyCodeGroups: res.taxonomyCodeGroups,
            networkTypes: res.networkTypes,
            adminUsers: res.adminUsers
          }

          this.cache[`admin/data`] = _res

          return _res
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerifications(status: string): Promise<VerificationsResponse> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications?status=${status}`).pipe(
        map((res: any) => {
          return VerificationsResponse.fromJson(res)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerification(verificationId: string): Promise<Verification> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}`).pipe(
        map((res: any) => {
          return Verification.fromJson(res.verification)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getResponse(verificationId: string, responseId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}/responses/${responseId}`).pipe(
        map((res: any) => {
          if (!res || !res.response) {
            return null
          }

          return JSON.parse(res.response)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayers(): Promise<Payer[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers`).pipe(
        map((res: any) => {
          return res.payers.map(data => Payer.fromJson(data))
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getDiscoveryRequests(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/discovery`).pipe(
        map((res: any) => {
          return res.discoveryRequests
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getDiscoveryRequest(discoveryId: string): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/discovery/${discoveryId}`).pipe(
        map((res: any) => {
          return res.discoveryRequest
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getDiscoveryResponse(
    discoveryId: string,
    responseId: number): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/discovery/${discoveryId}/responses/${responseId}`).pipe(
        map((res: any) => {
          return res.discoveryResponse
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getRelatedEntities(): Promise<RelatedEntity[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/related-entities`).pipe(
        map((res: any) => {
          return res.relatedEntities.map((data: any) => {
            return RelatedEntity.fromJson(data)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayer(payerId: string): Promise<Payer|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/${payerId}`).pipe(
        map((res: any) => {
          return Payer.fromJson(res.payer)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayerTaxonomyCode(
    payerId: string,
    taxonomyCode: string
  ): Promise<{
    payer: any,
    payerTaxonomyCodeFilters: any[],
    payerConfigurations: any[],
    payerTaxonomyCodeAccuracy: any[]
  }|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/${payerId}/taxonomy-codes/${taxonomyCode}`).pipe(
        map((res: any) => {
          return res
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updatePayer(
    payerId: string,
    phoneNumber: string|null,
    payerName: string|null,
    changeHealthcarePayerId: string|null,
    availityPayerId: string|null,
    serifPayerId: string|null,
    clearingHouseIdentifier: number|null,
    providerType: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerId}`, {
        phoneNumber,
        payerName,
        changeHealthcarePayerId,
        availityPayerId,
        serifPayerId,
        clearingHouseIdentifier,
        providerType
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateAssignees(
    verificationIds: string[],
    adminCognitoId: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/verifications/assignees`, {
        verificationIds,
        adminCognitoId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getRelatedPayers(payerId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/${payerId}/related-payers`).pipe(
        map((res: any) => {
          return res.relatedPayers
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getSpecialties(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/specialties`).pipe(
        map((res: any) => {
          return res.specialties
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getSpecialty(specialtyId: string): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/specialties/${specialtyId}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createRelatedPayer(payerId: string, relatedPayerId: string, taxonomyCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/${payerId}/related-payers`, {
        relatedPayerId,
        taxonomyCode
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteRelatedPayer(payerId: string, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/${payerId}/related-payers/${id}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteRelatedEntity(relatedEntityId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/related-entities/${relatedEntityId}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createPayer(payerName: string, changeHealthcarePayerId: string, standardPayerId: string, phoneNumber: string|null = null): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers`, {
        payerName,
        phoneNumber,
        changeHealthcarePayerId,
        standardPayerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createCustomer(
    emailAddress: string,
    customerName: string,
    maxProductionVerificationCount: number|null
  ): Promise<{
    emailAddress: string,
    password: string,
    productionApiKey: string,
    sandboxApiKey: string
  }> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/customers`, {
        emailAddress,
        customerName,
        maxProductionVerificationCount
      }).pipe(
        map((res: any) => {
          return {
            emailAddress: res.emailAddress,
            password: res.password,
            productionApiKey: res.productionApiKey,
            sandboxApiKey: res.sandboxApiKey
          }
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createAnalysis(
    payerId: string,
    taxonomyCode: string,
    serviceTypeCodes: string[],
    filterValues: string[],
    limit: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/taxonomy-codes/${taxonomyCode}/analysis`, {
        serviceTypeCodes,
        filterValues,
        limit
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateDefault(payerDefault: PayerDefault) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerDefault.payerId}`, {
        category: payerDefault.category,
        innPriorAuthRequired: payerDefault.innPriorAuthRequired,
        oonPriorAuthRequired: payerDefault.oonPriorAuthRequired,
        innPriorAuthRequiredNotes: payerDefault.innPriorAuthRequiredNotes,
        oonPriorAuthRequiredNotes: payerDefault.oonPriorAuthRequiredNotes
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateVerification(
    verificationId: string|null,
    customerId: string|null,
    patientId: string|null,
    coverageStartDate: string|null,
    coverageEndDate: string|null,
    innCopay: number|null,
    innCoinsurance: number|null,
    innTotalDeductible: number|null,
    innRemainingDeductible: number|null,
    innOutOfPocket: number|null,
    innOutOfPocketRemaining: number|null,
    innPriorAuthRequired: boolean|null,
    oonCopay: number|null,
    oonCoinsurance: number|null,
    oonTotalDeductible: number|null,
    oonRemainingDeductible: number|null,
    oonOutOfPocket: number|null,
    oonOutOfPocketRemaining: number|null,
    oonPriorAuthRequired: boolean|null,
    status: string,
    planName: string|null,
    resolutionMethod: string,
    carveOutPayerId: string|null,
    networkType: string|null
  ): Promise<any> {
 
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/verifications/${verificationId}`, {
      customerId,
      patientId,
      resolutionMethod,
      status,
      planName,
      carveOutPayerId,
      networkType,
      coverageStartDate,
      coverageEndDate,
      innCopay,
      innCoinsurance,
      innTotalDeductible,
      innRemainingDeductible,
      innOutOfPocket,
      innOutOfPocketRemaining,
      innPriorAuthRequired,
      oonCopay,
      oonCoinsurance,
      oonTotalDeductible,
      oonRemainingDeductible,
      oonOutOfPocket,
      oonOutOfPocketRemaining,
      oonPriorAuthRequired
    }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getCustomers(): Promise<Customer[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/customers`)
        .pipe(
          map((res: any) => {
            return res.customers.map(data => Customer.fromJson(data))
          })
        )
        .subscribe({
          next: resolve,
          error: reject
        })
    })
  }

  async getCustomer(customerId: string): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/customers/${customerId}`)
        .subscribe({
          next: resolve,
          error: reject
        })
    })
  }

  async updateCustomer(customerId: string, customer: Customer): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/customers/${customerId}`, { ...customer }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createCustomerProvider(
    customerId: string,
    payerId: string,
    state: string,
    npi: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/customers/${customerId}/providers`, {
        payerId,
        state,
        npi
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateCustomerProvider(
    customerId: string,
    id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/customers/${customerId}/providers/${id}`, {}).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteConfiguration(
    payerId: string,
    configurationId: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/configurations/${configurationId}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteState(
    payerId: string,
    state: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/states/${state}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createAliasPayerID(
    payerId: string,
    aliasPayerId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerId}`, {
        aliasPayerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createPayerState(
    payerId: string,
    state: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerId}`, {
        state
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createConfiguration(payerId: string, taxonomyCode: string, serviceTypeCode: string, transactionSet: string, priority: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/configurations`, {
        taxonomyCode,
        serviceTypeCode,
        transactionSet,
        priority
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteFilterValue(
    payerId: string,
    taxonomyCode: string,
    id: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/taxonomy-codes/${taxonomyCode}/filter-values/${id}`, {}).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createFilterValue(
    payerId: string,
    taxonomyCode: string,
    filterValue: string,
    prioritise: boolean,
    exclude: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/taxonomy-codes/${taxonomyCode}/filter-values`, {
        filterValue,
        prioritise,
        exclude
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createNewTaxonomyCodeConfigurations(taxonomyCode: string, requestServiceTypeCode: string, responseServiceTypeCode: string): Promise<any> {
    this.cache[`admin/data`] = null

    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/configurations`, {
        taxonomyCode,
        requestServiceTypeCode,
        responseServiceTypeCode
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createRelatedEntity(
    name: string|null,
    phoneNumber: string|null,
    type: string,
    payerId: string|null,
    pattern: string|null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/related-entities`, {
        name,
        phoneNumber,
        type,
        payerId,
        pattern
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async previewVerification(
    verificationId: string
  ): Promise<Verification> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}/preview`).pipe(
        map((res: any) => {
          return Verification.fromJson(res.verification)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

}
