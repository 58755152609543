import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-taxonomy-code-groups',
  templateUrl: './taxonomy-code-groups.component.html'
})
export class TaxonomyCodeGroupsComponent {

  taxonomyCodeGroups: any[] = []

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Groups")

    const data = await this.adminService.getData()

    this.taxonomyCodeGroups = data.taxonomyCodeGroups
  }
}
