<div class="admin-dropdown" *ngIf="openAdminAssigneesModal" (click)="selectAdmin(null)">
	<ul>
		<li *ngFor="let admin of adminUsers" (click)="selectAdmin(admin)">
			<span class="admin-initials circle circle-initials"
				[ngStyle]="{ 'background-color': getAdminInitialsColor(admin.adminCognitoId) }">{{
				admin.firstName.charAt(0) + admin.lastName.charAt(0) }}</span>
			{{ admin.firstName }} {{ admin.lastName }}
		</li>
	</ul>
</div>
