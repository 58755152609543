export const ERRORS = {
    ADMIN_COGNITO_ID_REQUIRED: 'adminCognitoId is required',
    ALPHA_NUMERIC_CHARS_ONLY: 'Only alphanumeric characters are allowed',
    CARVE_OUT_PAYER_ADDED: 'Carve Out Payer added successfully',
    CARVE_OUT_PAYER_ADD_ERROR: 'Error adding Carve Out Payer',
    CARVE_OUT_PAYER_NAME_REQUIRED: 'Carve Out Payer Name is required',
    CARVE_OUT_PAYER_REQUIRED: 'Carve Out Payer is required',
    EIGHT_CHARS_REQUIRED: 'Eight characters are required',
    PATTERN_REQUIRED: 'Pattern is required',
    PAYER_ID_REQUIRED: 'Payer ID is required',
    PAYER_REQUIRED: 'Payer is required',
    POSITIVE_INTEGERS_ONLY: 'Only positive integers are allowed',
};
