<div class="header">
	<h1>Payers</h1>
	<div>
		<a class="button" [routerLink]="['/create-payer']">Create Payer</a>
	</div>
</div>
<div class="payers">
	<table>
		<thead>
			<td>Payer</td>
			<td>Change ID</td>
			<td>Availity ID</td>
			<td>Sohar ID</td>
			<td>Payer ID</td>
			<td>Serif Payer ID</td>
			<td>BCBS</td>
			<td>Discovery</td>
			<td>Clearing House</td>
		</thead>
		<tbody *ngFor="let payer of payers">
			<tr>
				<td>
					<a [routerLink]="['/payers', payer.payerId]">{{ payer.payerName }}</a>
				</td>
				<td>{{ payer.changeHealthcarePayerId }}</td>
				<td>{{ payer.availityPayerId }}</td>
				<td>{{ payer.payerId }}</td>
				<td>{{ payer.standardPayerId }}</td>
				<td>{{ payer.serifPayerId }}</td>
				<td>{{ payer.bluesPayer == true ? "Yes" : "No" }}</td>
				<td>{{ payer.discovery == true ? "Yes" : "No" }}</td>
				<td>{{ payer.clearingHouse }}</td>
			</tr>
		</tbody>
	</table>
</div>