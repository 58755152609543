import { Configuration } from "./configuration"

export class ConfigurationGroup {

	newRequestServiceTypeCode: string|null = null
	newResponseServiceTypeCode: string|null = null

	constructor(
		public displayName: string,
		public taxonomyCode: string,
		public configurations: Configuration[]) {}

	public get requestConfigurations(): Configuration[] {
		return this.configurations.filter((configuration) => {
			return configuration.transactionSet == "270"
		})
	}

	public get responseConfigurations(): Configuration[] {
		return this.configurations.filter((configuration) => {
			return configuration.transactionSet == "271"
		})
	}

	static fromJson(data: any): ConfigurationGroup {
		return new ConfigurationGroup(data.displayName, data.taxonomyCode, data.configurations.map(c => Configuration.fromJson(c)))
	}
}