import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Note } from '../models/note'
import { map } from 'rxjs/operators'
import { Verification } from "../models/verification"

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getNotes(
    verificationId: string
  ): Promise<Note[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/notes/${verificationId}`).pipe(
        map((response: any) => {
          return response.notes.map(note => {
            return Note.fromJson(note)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createNote(
    verification: Verification,
    content: string|null,
    jiraTicketTitle: string|null = null,
    jiraTicketDescription: string|null = null
  ): Promise<any> {
    const verificationId = verification.verificationId
    const payerId = verification.payerId
    const taxonomyCode = verification.taxonomyCode
    const placeOfServiceCode = verification.placeOfServiceCode
    const serviceTypeCode = verification.serviceTypeCode
    const customerId = verification.customerId

    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/notes/${verificationId}`, {
        content,
        jiraTicketTitle,
        jiraTicketDescription,
        payerId,
        taxonomyCode,
        placeOfServiceCode,
        serviceTypeCode,
        customerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
