import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PayersComponent } from './payers/payers.component';
import { VerificationComponent } from './verification/verification.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { PayerComponent } from './payer/payer.component';
import { CreatePayerComponent } from './create-payer/create-payer.component';
import { EditPayerComponent } from './edit-payer/edit-payer.component';
import { RelatedEntitiesComponent } from './related-entities/related-entities.component';
import { CustomersComponent } from './customers/customers.component';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomerComponent } from './customer/customer.component';
import { DiscoveryComponent } from './discovery/discovery.component';
import { DiscoveryDetailComponent } from './discovery-detail/discovery-detail.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { TotpComponent } from './totp/totp.component';
import { TaxonomyCodeComponent } from './taxonomy-code/taxonomy-code.component';
import { TaxonomyCodeGroupsComponent } from './taxonomy-code-groups/taxonomy-code-groups.component';
import { SpecialtiesComponent } from './specialties/specialties.component';
import { SpecialtyComponent } from './specialty/specialty.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    PayersComponent,
    VerificationComponent,
    ConfirmationModalComponent,
    PayerComponent,
    CreatePayerComponent,
    EditPayerComponent,
    RelatedEntitiesComponent,
    CustomersComponent,
    CreateCustomerComponent,
    CustomerComponent,
    DiscoveryComponent,
    DiscoveryDetailComponent,
    AuthenticatorComponent,
    TotpComponent,
    TaxonomyCodeComponent,
    TaxonomyCodeGroupsComponent,
    SpecialtiesComponent,
    SpecialtyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
