import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Customer } from "../@shared/models/customer"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html'
})
export class CustomerComponent {

  customer: Customer
  customerId: string
  users: any[] = []

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Customer")
    this.customerId = this.route.snapshot.paramMap.get("id")
    this.getCustomer()
  }

  async getCustomer() {
    const res = await this.adminService.getCustomer(this.customerId)
    this.customer = res.customer
    this.users = res.users
  }
}
