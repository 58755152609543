import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, COGNITO_RESPONSES } from '../@shared/services/auth.service';
import { Title } from "@angular/platform-browser"
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-totp',
  templateUrl: './totp.component.html'
})
export class TotpComponent {

  form: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Authenticator")

    this.form = this.formBuilder.group({
      code: ['', [Validators.required]]
    })
  }

  async onSubmit() {
    const code = this.form.value.code

    if (!code) {
      return
    }

    try {
      await this.authService.submitSoftwareToken(
        code
      )

      this.router.navigate([''])
    } catch (error) {
      console.log(error)
      this.router.navigate(['login'])
    }
  }
}
