<div *ngIf="isVisible" class="modal-backdrop">
    <div class="modal">
        <div class="modal-body">
            <p>{{ question }}</p>
        </div>
        <div class="modal-footer">
            <button (click)="onCancel()">Cancel</button>
            <button class="confirm" (click)="onSubmit()">{{ actionButton }}</button>
        </div>
    </div>
</div>