export class NetworkCoverage {
	constructor(
		public cost: number,
		public copay: number,
		public coinsurance: number,
		public totalDeductible: number,
		public remainingDeductible: number,
		public outOfPocket: number,
		public outOfPocketRemaining: number,
		public priorAuthRequired: boolean,
		public networkStatus: string,
		public tier: number|null
	) {}

	get deductible(): string {
		if (this.remainingDeductible != null && this.totalDeductible != null) {
			return `$${this.remainingDeductible} of $${this.totalDeductible} remaining`
		} else if (this.remainingDeductible == null && this.totalDeductible != null) {
			return `$${this.totalDeductible}`
		} else {
			return ""
		}
	}

	get deductibleMet(): boolean {
		return this.remainingDeductible === 0
	}

	get oop(): string {
		if (this.outOfPocketRemaining != null && this.outOfPocket != null) {
			return `$${this.outOfPocketRemaining} of $${this.outOfPocket} remaining`
		} else if (this.outOfPocketRemaining == null && this.outOfPocket != null) {
			return `$${this.outOfPocket}`
		} else {
			return ""
		}
	}

	static fromJson(data: any): NetworkCoverage {
		return new NetworkCoverage(
			data.cost,
			data.copay,
			data.coinsurance,
			data.totalDeductible,
			data.remainingDeductible,
			data.outOfPocket,
			data.outOfPocketRemaining,
			data.priorAuthRequired,
			data.networkStatus,
			data.tier
		)
	}
}
