import { Component, Input } from '@angular/core';
import * as CONSTANTS from '../@shared/constants';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.less']
})
export class ConfirmationModalComponent {
  @Input() question: string = CONSTANTS.COMMON.CONFIRM_PROCEED;
  @Input() actionButton: string = CONSTANTS.COMMON.CONFIRM;
  isVisible: boolean = false;
  onConfirm: () => void;

  onCancel() {
    this.isVisible = false;
  }

  onSubmit() {
    this.isVisible = false;
    if (this.onConfirm) {
      this.onConfirm()
    }
  }

  show(onConfirm: () => void, question: string, actionButton: string) {
    this.onConfirm = onConfirm;
    this.question = question !== null && question !== undefined ? question : this.question;
    this.actionButton = actionButton !== null && actionButton !== undefined ? actionButton : this.actionButton;
    this.isVisible = true;
  }
}
