export class BenefitsRelatedEntity {

	constructor(
		public name: string,
		public phoneNumber: string
	) {}

	static fromJson(data: any|null): BenefitsRelatedEntity|null {
		if (!data) {
			return null
		}

		return new BenefitsRelatedEntity(
			data.benefitsRelatedEntity,
			data.benefitsRelatedEntityPhoneNumber
		)
	}
}
