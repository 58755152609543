export class Configuration {

	constructor(
		public transactionSet: string,
		public serviceTypeCode: string,
		public priority: number,
		public configurationId: number) {}

	static fromJson(data: any): Configuration {
		return new Configuration(
			data.transactionSet,
			data.serviceTypeCode,
			data.priority,
			data.configurationId)
	}
}
