<div class="header">
	<h1>Create Payer</h1>
</div>
<div class="admin">
	<section>
		<form [formGroup]="form" (ngSubmit)="didClickCreatePayer()">
			<label>Payer Name</label>
			<input type="text" formControlName="payerName" required placeholder="Enter payer name">
			<label>Change ID</label>
			<input type="text" formControlName="changeHealthcarePayerId" required placeholder="Enter Change Healthcare payer ID">
			<label>Payer ID</label>
			<input type="text" formControlName="standardPayerId" required placeholder="Enter the standard payer ID">
			<label>Phone Number (Optional)</label>
			<input type="text" formControlName="phoneNumber" placeholder="Enter phone number">
			<button>Submit</button>
		</form>
	</section>
</div>
