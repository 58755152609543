import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { DiscoveryRequest } from "../@shared/models/discoveryRequest"
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-discovery-detail',
  templateUrl: './discovery-detail.component.html'
})
export class DiscoveryDetailComponent {

  discoveryRequest: DiscoveryRequest|null = null

  statusMap = {
    0: "Not Found",
    1: "Unavailable",
    2: "Found",
    3: "Inactive"
  }

  constructor(
    private adminService: AdminService,
    private titleService: Title,
    private route: ActivatedRoute) {}

  async ngOnInit() {
    const discoveryId = this.route.snapshot.paramMap.get("id")
    this.titleService.setTitle("Discovery")
    this.discoveryRequest = await this.adminService.getDiscoveryRequest(discoveryId)
  }

  async getResponse(discoveryResponse: any) {
    const discoveryId = discoveryResponse.discoveryId
    const responseId = discoveryResponse.responseId

    try {
      const _discoveryResponse = await this.adminService.getDiscoveryResponse(
        discoveryId,
        responseId)

      if (!_discoveryResponse ||
        !_discoveryResponse.res) {
        return
      }

      discoveryResponse.res = JSON.parse(_discoveryResponse.res)
    } catch (error: any) {
      console.log(error)
    }
  }
}
