<div class="header">
	<h1>{{ payer?.payerName }}</h1>
	<div>
		<a class="button" [routerLink]="['/payers', payer?.payerId, 'edit']">Edit</a>
		<a class="button" (click)="didClickRetryVerifications()">Retry Pending Verifications</a>
	</div>
</div>
<div *ngIf="payer">
	<table>
		<thead>
			<td>Change ID</td>
			<td>Availity ID</td>
			<td>Sohar ID</td>
			<td>Serif Payer ID</td>
			<td>Phone Number</td>
			<td>BCBS</td>
			<td>Clearing House</td>
		</thead>
		<tbody>
			<tr>
				<td>{{ payer.changeHealthcarePayerId }}</td>
				<td>{{ payer.availityPayerId }}</td>
				<td>{{ payer.payerId }}</td>
				<td>{{ payer.serifPayerId }}</td>
				<td>{{ payer.phoneNumber }}</td>
				<td>{{ payer.bluesPayer == true ? "Yes" : "No" }}</td>
				<td>{{ payer.clearingHouse }}</td>
			</tr>
		</tbody>
	</table>
	<table>
		<thead>
			<td>Payer IDs</td>
			<td>Primary Payer ID</td>
		</thead>
		<tbody>
			<tr *ngFor="let alias of payer.aliases">
				<td>{{ alias.id }}</td>
				<td>{{ alias.isStandardPayerId ? "Yes" : "" }}</td>
			</tr>
			<tr>
				<td>
					<input type="text" placeholder="Add a new alias payer ID" [(ngModel)]="aliasPayerId" />
				</td>
				<td>
					<a class="button" (click)="didClickAddPayerAlias()">Add</a>
				</td>
			</tr>
		</tbody>
	</table>
	<table>
		<thead>
			<td colspan="2">State</td>
		</thead>
		<tbody>
			<tr *ngFor="let state of payer.states">
				<td>{{ state }}</td>
				<td>
					<a class="button" (click)="didClickDeleteState(state)">Delete</a>
				</td>
			</tr>
			<tr>
				<td>
					<select [(ngModel)]="state">
						<option value="">
							Select a state
						</option>
						<option *ngFor="let _state of states" [value]="_state">
							{{ _state }}
						</option>
					</select>
				</td>
				<td>
					<a class="button" (click)="didClickAddState()">Add</a>
				</td>
			</tr>
		</tbody>
	</table>
	<table>
		<thead>
			<td>Group</td>
			<td>Name</td>
		</thead>
		<tbody>
			<tr *ngFor="let taxonomyCodeGroup of taxonomyCodeGroups">
				<td>
					<a [routerLink]="['/payers', payer.payerId, 'taxonomy-codes', taxonomyCodeGroup.taxonomyCodeGroup]">
						{{ taxonomyCodeGroup.taxonomyCodeGroup }}
					</a>
				</td>
				<td>{{ taxonomyCodeGroup.name }}</td>
			</tr>
		</tbody>
	</table>
	<h3>Related Payers</h3>
	<table>
		<thead>
			<td>Payer</td>
			<td>Taxonomy</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let payer of relatedPayers">
				<td>{{ payer.payerName }}</td>
				<td>{{ payer.taxonomyCode }}</td>
				<td><a class="button" (click)="didClickDeleteRelatedPayer(payer.id)">Delete</a></td>
			</tr>
		</tbody>
	</table>
	<form [formGroup]="relatedPayerForm" (ngSubmit)="didSubmitCreateRelatedPayerForm()" class="inline">
		<select formControlName="relatedPayerId" required>
			<option value="null">Select a related payer</option>
			<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }} - {{ payer.changeHealthcarePayerId }}</option>
		</select>
		<select formControlName="taxonomyCodeGroup" required>
			<option [value]="null">Select a taxonomy code group</option>
			<option *ngFor="let taxonomyCodeGroup of taxonomyCodeGroups" [value]="taxonomyCodeGroup.taxonomyCodeGroup">
				{{ taxonomyCodeGroup.name }} - {{ taxonomyCodeGroup.taxonomyCodeGroup }}
			</option>
		</select>
		<button>Submit</button>
	</form>
</div>