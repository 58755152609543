import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Customer } from "../@shared/models/customer"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html'
})
export class CustomersComponent {

  customers: Customer[] = []

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Customers")
    this.getCustomers()
  }

  async getCustomers() {
    this.customers = await this.adminService.getCustomers()
  }
}
