import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from "../@shared/services/admin.service"
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-create-payer',
  templateUrl: './create-payer.component.html'
})
export class CreatePayerComponent {

  form: UntypedFormGroup

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Create Payer")

    this.form = this.formBuilder.group({
      payerName: ["", [Validators.required]],
      phoneNumber: [null, []],
      changeHealthcarePayerId: ["", [Validators.required]],
      standardPayerId: ["", [Validators.required]]
    })
  }

  async didClickCreatePayer() {
    const payerName = this.form.value.payerName
    const phoneNumber = this.form.value.phoneNumber
    const changeHealthcarePayerId = this.form.value.changeHealthcarePayerId
    const standardPayerId = this.form.value.standardPayerId

    if (!payerName ||
      !changeHealthcarePayerId ||
      !standardPayerId) {
      return
    }

    try {
      await this.adminService.createPayer(payerName, changeHealthcarePayerId, standardPayerId, phoneNumber)
      alert("Payer created")
      this.router.navigate(["/payers"])
    } catch (error) {
      alert(error)
    }
  }
}
