<div class="header">
	<h1>Customer</h1>
</div>

<table *ngIf="customer">
	<thead>
		<td>Name</td>
		<td>Created</td>
	</thead>
	<tbody>
		<tr>
			<td>{{ customer.name }}</td>
			<td>{{ customer.timestamp| date: 'MM/dd/yyyy' }}</td>
		</tr>
	</tbody>
</table>

<table>
	<thead>
		<td>Payer</td>
		<td>State</td>
		<td>NPI</td>
		<td>Provider Type</td>
		<td>Active</td>
		<td>Created</td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let provider of providers">
			<td>{{ provider.payerName }}</td>
			<td>{{ provider.state }}</td>
			<td>{{ provider.npi }}</td>
			<td>{{ provider.providerType }}</td>
			<td>{{ provider.active == 1 ? "Yes" : "No" }}</td>
			<td>{{ provider.created | date }}</td>
			<td>
				<a class="button" (click)="didClickEnableProvider(provider.id)">{{ provider.active == 1 ? "Disable" : "Enable" }}</a>
			</td>
		</tr>
		<tr>
			<td>
				<select [(ngModel)]="payerId">
					<option value="">Select a Payer</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }}</option>
				</select>
			</td>
			<td>
				<select [(ngModel)]="state">
					<option value="">Select a State</option>
					<option *ngFor="let state of states" [value]="state">{{ state }}</option>
				</select>
			</td>
			<td>
				<input type="text" placeholder="NPI" [(ngModel)]="npi" />
			</td>
			<td>
				<a class="button" (click)="didClickAddProvider()">Submit</a>
			</td>
		</tr>
	</tbody>
</table>

<table>
	<thead>
		<td>Email Address</td>
		<td>Role</td>
	</thead>
	<tbody>
		<tr *ngFor="let user of users">
			<td>{{ user.emailAddress }}</td>
			<td>{{ user.role }}</td>
		</tr>
	</tbody>
</table>
