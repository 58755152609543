import { Component } from '@angular/core';
import { AuthService } from "../@shared/services/auth.service"

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {

  isExternalUser: boolean = false

  constructor(
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.isExternalUser = await this.authService.hasGroup("external")
  }
}
