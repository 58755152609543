import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { AdminService } from "../@shared/services/admin.service"
import { Configuration } from "../@shared/models/configuration"
import { SERVICE_TYPE_CODES } from "../@shared/constants/data"

@Component({
  selector: 'app-taxonomy-code',
  templateUrl: './taxonomy-code.component.html'
})

export class TaxonomyCodeComponent {

  payerId: string|null = null
  taxonomyCode: string|null = null
  filters: {
    id: number,
    value: string,
    prioritise: boolean,
    exclude: boolean
  }[] = []
  configurations: Configuration[] = []
  requestConfigurations: Configuration[] = []
  responseConfigurations: Configuration[] = []
  requestServiceTypeCode: string|null = null
  responseServiceTypeCode: string|null = null
  serviceTypeCodes: {
    code: string,
    name: string
  }[] = []
  filterValue: string|null = null
  analysisValue: string|null = null
  analysisValueType: string = "serviceTypeCode"
  prioritiseFilterValue: string = "true"
  excludeFilterValue: string = "false"
  payer: any
  analysis: {
    filters: string[],
    serviceTypeCodes: string[]
  } = {
    filters: [],
    serviceTypeCodes: []
  }

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.payerId = this.route.snapshot.paramMap.get("id")
    this.taxonomyCode = this.route.snapshot.paramMap.get("taxonomyCode")
  
    if (
      !this.payerId ||
      !this.taxonomyCode
    )  {
      alert("Something went wrong")
      return
    }

    this.serviceTypeCodes = SERVICE_TYPE_CODES

    await this.getPayerTaxonomyCode()
  }

  async getPayerTaxonomyCode() {
    const res = await this.adminService.getPayerTaxonomyCode(
      this.payerId,
      this.taxonomyCode
    )

    this.payer = res.payer
    this.filters = res.payerTaxonomyCodeFilters || []
    this.configurations = res.payerConfigurations
    this.requestConfigurations = res.payerConfigurations.filter((obj: any) => {
      return obj.transactionSet == "270"
    })
    this.responseConfigurations = res.payerConfigurations.filter((obj: any) => {
      return obj.transactionSet == "271"
    })
  }

  async didClickDeleteConfiguration(
    configuration: Configuration
  ) {
    const payerId = this.payerId
    const configurationId = configuration.configurationId

    if (
      !payerId ||
      !configurationId
    ) {
      return
    }

    const confirmed = confirm("Are you sure you want to delete this configuration?")

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deleteConfiguration(
        payerId,
        configurationId
      )
      alert("Configuration deleted")
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickDeleteFilterValue(
    id: number
  ) {
    const payerId = this.payerId
    const taxonomyCode = this.taxonomyCode

    if (
      !payerId||
      !taxonomyCode ||
      id == null ||
      id == undefined
    ) {
      return
    }

    const confirmed = confirm(
      `Are you sure you want to delete this value?`
    )

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deleteFilterValue(
        payerId,
        taxonomyCode,
        id
      )
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickAddFilterValue() {
    const payerId = this.payerId
    const taxonomyCode = this.taxonomyCode
    const filterValue = this.filterValue
    const prioritise = this.prioritiseFilterValue == "true"
    const exclude = this.excludeFilterValue == "true"

    if (!payerId||
      !taxonomyCode ||
      !filterValue) {
      return
    }

    try {
      await this.adminService.createFilterValue(
        payerId,
        taxonomyCode,
        filterValue,
        prioritise,
        exclude)

      this.filterValue = null
      this.prioritiseFilterValue = "true"
      this.excludeFilterValue = "false"
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  didClickDeleteAnalysisServiceTypeCode(serviceTypeCode: string) {
    const index = this.analysis.serviceTypeCodes.indexOf(serviceTypeCode)
    this.analysis.serviceTypeCodes.splice(index, 1)
  }

  didClickDeleteAnalysisFilterValue(filter: string) {
    const index = this.analysis.filters.indexOf(filter)
    this.analysis.filters.splice(index, 1)
  }

  didClickAddAnalysisValue() {
    const type = this.analysisValueType

    if (type == "serviceTypeCode") {
      if (!this.serviceTypeCodes.map(obj => obj.code).includes(this.analysisValue)) {
        alert("invalid service type code")
        return
      }
      this.analysis.serviceTypeCodes.push(this.analysisValue)
    } else {
      if (!this.analysisValue.startsWith("++") &&
        !this.analysisValue.startsWith("--")) {
        alert("filter value should start with ++ or --")
        return
      }
      this.analysis.filters.push(this.analysisValue)
    }

    this.analysisValue = null
  }

  async didClickSubmitAnalysis() {
    const payerId = this.payerId
    const taxonomyCode = this.taxonomyCode
    const serviceTypeCodes = this.analysis.serviceTypeCodes
    const filterValues = this.analysis.filters

    if (serviceTypeCodes.length == 0) {
      alert("At least one service type code is required")
      return
    } else if ((serviceTypeCodes.length + filterValues.length) > 5) {
      alert("Maximum 5 variables")
      return
    }

    try {
      await this.adminService.createAnalysis(
        payerId,
        taxonomyCode,
        serviceTypeCodes,
        filterValues,
        100)
      alert("Job created")
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickAddServiceTypeCode(
    serviceTypeCode: string,
    transactionSet: string
  ) {
    const payerId = this.payerId
    const taxonomyCode = this.taxonomyCode

    if (
      !payerId||
      !taxonomyCode ||
      !transactionSet ||
      !serviceTypeCode
    ) {
      return
    }

    let priority = 0

    if (
      transactionSet == "270" &&
      this.requestConfigurations.length > 0
    ) {
      priority = this.requestConfigurations[this.requestConfigurations.length - 1].priority + 1
    } else if (
      transactionSet == "271" &&
      this.responseConfigurations.length > 0
    ) {
      priority = this.responseConfigurations[this.responseConfigurations.length - 1].priority + 1
    }

    try {
      await this.adminService.createConfiguration(
        payerId,
        taxonomyCode,
        serviceTypeCode,
        transactionSet,
        priority
      )
      this.requestServiceTypeCode = null
      this.responseServiceTypeCode = null
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }
}
