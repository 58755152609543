<div class="header">
	<h1>Discovery</h1>
</div>
<div>
	<table>
		<thead>
			<td>Payer</td>
			<td>Status</td>
			<td>Created</td>
			<td></td>
		</thead>
		<tbody *ngFor="let discoveryResponse of discoveryRequest?.discoveryResponses || []">
			<tr>
				<td>{{ discoveryResponse.payerName }}</td>
				<td>{{ statusMap[discoveryResponse.status] }}</td>
				<td>{{ discoveryResponse.timestamp | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
				<td>
					<a *ngIf="discoveryResponse.hasRes && (discoveryResponse.res == null || discoveryResponse.res == undefined)" class="button" (click)="getResponse(discoveryResponse)">Show</a>
					<pre *ngIf="discoveryResponse.res != null || discoveryResponse.res != undefined">{{ discoveryResponse.res | json }}</pre>
				</td>
			</tr>
		</tbody>
	</table>
</div>
