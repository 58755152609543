<div class="header">
	<h1>Specialties</h1>
</div>
<table>
	<thead>
		<td>ID</td>
		<td>Name</td>
	</thead>
	<tbody>
		<tr *ngFor="let specialty of specialties">
			<td>
				<a [routerLink]="['/specialties', specialty.specialtyId]">
					{{ specialty.specialtyId }}
				</a>
			</td>
			<td>{{ specialty.name }}</td>
		</tr>
	</tbody>
</table>
