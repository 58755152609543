import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { VerificationsResponse } from "../@shared/models/verifications-response"
import { Verification } from "../@shared/models/verification"
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from "@angular/platform-browser"
import { AuthService } from "../@shared/services/auth.service"
import { AdminUser } from "../@shared/models/admin-user"
import { stringToColor } from "../@shared/helpers/colors"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent {

  currentVerification: Verification;
  errorMessage: string = null;
  filterAdminCognitoId: string = "";
  filterCustomer: string = ""
  filterPayer: string = ""
  filterStatus: string = "pending"
  verifications: Verification[] = []
  filterBenefitsRelatedEntity: string = ""
  benefitsRelatedEntities: string[] = []
  orderValue: string = "newest"
  isExternalUser = false
  adminUsers: AdminUser[] = []
  openAdminAssigneesModal = false

  constructor(
    private authService: AuthService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) { }

  async ngOnInit() {
    this.titleService.setTitle("Dashboard")

    const queryParams: any = this.route.snapshot.queryParams

    if (queryParams) {
      if (queryParams.status) {
        this.filterStatus = queryParams.status;
      }
      if (queryParams.payer) {
        this.filterPayer = queryParams.payer;
      }
      if (queryParams.customer) {
        this.filterCustomer = queryParams.customer;
      }
      if (queryParams.admin) {
        this.filterAdminCognitoId = queryParams.admin;
      }
    }

    this.getVerifications()

    this.isExternalUser = await this.authService.hasGroup("external")

    if (this.isExternalUser) {
      this.orderValue = "oldest"
    }

    const data = await this.adminService.getData()

    this.adminUsers = data.adminUsers
  }

  async didSelectFilter() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        status: this.filterStatus,
        payer: this.filterPayer,
        customer: this.filterCustomer,
        admin: this.filterAdminCognitoId
      }
    })

    this.getVerifications()
  }

  async getVerifications() {
    const res = await this.adminService.getVerifications(this.filterStatus)

    this.verifications = res.verifications
    this.benefitsRelatedEntities = [...new Set(this.verifications.filter(verification => verification.benefitsRelatedEntity.name).map(verification => verification.benefitsRelatedEntity.name))].sort((a, b) => {
      if (a == b) {
        return 0
      }
      return a > b ? 1 : -1
    })
  }

  filteredAdmins(): AdminUser[] {
    const uniqueAdmins: { [id: string]: AdminUser } = {};
    for (let verification of this.verifications) {
      const { adminCognitoId, adminFirstName, adminLastName } = verification;
      if (adminCognitoId && adminFirstName && adminLastName && !uniqueAdmins[adminCognitoId]) {
        uniqueAdmins[adminCognitoId] = {
          adminCognitoId,
          firstName: adminFirstName,
          lastName: adminLastName,
          fullName: `${adminFirstName} ${adminLastName}`
        };
      }
    }
    return Object.values(uniqueAdmins);
  }

  filteredPayers(): any[] {
    return [...new Set(this.verifications
      .map(v => v.changeHealthcarePayerId))]
      .sort((a: string, b: string) => a > b ? 1 : (a < b ? -1 : 0))
  }

  filteredCustomers(): any[] {
    return [...new Set(this.verifications.map(v => v.customerName))];
  }

  filteredVerifications(): any[] {
    return this.verifications.filter(v => {
      let adminFilterValue = false
      let customerFilterValue = false
      let payerFilterValue = false
      let benefitsRelatedEntityFilterValue = false
      if (this.filterPayer == "") {
        payerFilterValue = true
      } else {
        payerFilterValue = this.filterPayer == v.changeHealthcarePayerId
      }
      if (this.filterCustomer == "") {
        customerFilterValue = true;
      } else {
        customerFilterValue = this.filterCustomer == v.customerName;
      }
      if (this.filterAdminCognitoId === "") {
        adminFilterValue = true;
      } else if (this.filterAdminCognitoId === "unassigned") {
        adminFilterValue = !v.adminCognitoId;
      } else {
        adminFilterValue = this.filterAdminCognitoId === v.adminCognitoId;
      }
      if (this.filterBenefitsRelatedEntity == "") {
        benefitsRelatedEntityFilterValue = true
      } else {
        benefitsRelatedEntityFilterValue = this.filterBenefitsRelatedEntity == v.benefitsRelatedEntity?.name
      }
      return payerFilterValue && customerFilterValue && adminFilterValue && benefitsRelatedEntityFilterValue
    }).sort((a, b) => {
      if (a.timestamp == b.timestamp) {
        return 0
      }

      const value = this.orderValue == "newest" ? -1 : 1

      return a.timestamp > b.timestamp ? value : -(value)
    })
  }

  openAdminList(
    verification: Verification
  ) {
    if (this.isExternalUser) {
      return
    }

    this.currentVerification = verification
    this.openAdminAssigneesModal = true
  }

  dismissError() {
    this.errorMessage = null;
  }

  didClickOrderByCreated() {
    this.orderValue = this.orderValue == "newest" ? "oldest" : "newest"
  }

  private adminColors: { [key: string]: string } = {}

  getAdminInitialsColor(adminCognitoId: string): string {
    if (!adminCognitoId) {
      return '#E6E6E6';
    }
    if (!this.adminColors[adminCognitoId]) {
      let color = stringToColor(adminCognitoId);
      this.adminColors[adminCognitoId] = color;
    }
    return this.adminColors[adminCognitoId];
  }
}

