export const COMMON = {
    CONFIRM: 'Confirm',
    CONFIRM_PROCEED: 'Are you sure you want to proceed?',
    CONFIRM_SUBMIT: 'Are you sure you want to submit?',
    DEFAULT: 'default',
    PATTERN: {
        CARVE_OUT: {
            PREFIX: {
                NAME: 'Prefix',
                VALUE: 'prefix',
            },
            SUFFIX: {
                NAME: 'Suffix',
                VALUE: 'suffix',
            },
            CHAR_NUM: {
                NAME: 'Number of characters',
                VALUE: 'char-num',
            },
            NONE: {
                NAME: 'None',
                VALUE: null,
            }
        }
    },
    SUBMIT: 'Submit',
};
