<div class="filters fixed">
	<select [(ngModel)]="filterStatus" (change)="didSelectFilter()">
		<option value="pending">
			Pending
		</option>
		<option value="pending.eligible">
			Pending - Eligible
		</option>
		<option value="pending.carveout">
			Pending - Carve Out
		</option>
		<option value="pending.payer.unavailable">
			Pending - Payer Unavailable
		</option>
		<option value="complete">
			Complete
		</option>
		<option value="error">
			Error
		</option>
	</select>
	<select [(ngModel)]="filterPayer" (change)="didSelectFilter()">
		<option value="">
			All Payers
		</option>
		<option *ngFor="let payer of filteredPayers()" [value]="payer">
			{{ payer }}
		</option>
	</select>
	<select [(ngModel)]="filterCustomer" (change)="didSelectFilter()">
		<option value="">
			All Customers
		</option>
		<option *ngFor="let customer of filteredCustomers()" [value]="customer">
			{{ customer }}
		</option>
	</select>
	<select [(ngModel)]="filterAdminCognitoId" (change)="didSelectFilter()">
		<option value="">
			All Assignees
		</option>
		<option value="unassigned">
			Unassigned
		</option>
		<option *ngFor="let admin of filteredAdmins()" [value]="admin.adminCognitoId">
			{{ admin.firstName }} {{ admin.lastName }}
		</option>
	</select>
	<select [(ngModel)]="filterBenefitsRelatedEntity" (change)="didSelectFilter()">
		<option value="">
			All Related Entities
		</option>
		<option *ngFor="let benefitsRelatedEntity of benefitsRelatedEntities" [value]="benefitsRelatedEntity">
			{{ benefitsRelatedEntity }}
		</option>
	</select>
	<span>{{ filteredVerifications().length }} Result{{ filteredVerifications().length == 1 ? "" : "s" }}</span>
</div>
<div *ngIf="errorMessage" class="alert alert-error">
	<span>{{ errorMessage }}</span>
	<button (click)="dismissError()">x</button>
</div>
<div class="top-padded">
	<table>
		<thead>
			<td>Member ID</td>
			<td>Customer</td>
			<td>Name</td>
			<td>Payer</td>
			<td>Status</td>
			<td>Related Entity</td>
			<td>
				<a (click)="didClickOrderByCreated()">
					Created <span class="arrow" [class.rotate]="orderValue == 'oldest'"></span>
				</a>
			</td>
			<td>Assignee</td>
		</thead>
		<tbody>
			<tr *ngFor="let verification of filteredVerifications()">
				<td>
					<a [routerLink]="['/verification', verification.verificationId]"
						[queryParams]="{status: filterStatus, payer: filterPayer, customer: filterCustomer, admin: filterAdminCognitoId}">
						{{ verification.memberId }}
					</a>
				</td>
				<td>
					<a [routerLink]="['/customers', verification.customerId]">{{ verification.customerName }}</a>
				</td>
				<td>{{ verification.firstName }} {{ verification.lastName }}</td>
				<td>
					<a [routerLink]="['/payers', verification.payerId]">{{ verification.payerName }}</a>
				</td>
				<td>{{ verification.status }}</td>
				<td>{{ verification.benefitsRelatedEntity.name }}</td>
				<td [class.warning]="verification.serviceLevelAgreementWarning">{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</td>
				<td>
					<div
						class="circle"
						*ngIf="verification.status.startsWith('pending') || verification.resolutionMethod != 'API'"
						[ngClass]="verification.adminInitials ? 'circle-initials' : 'circle-human-figure'"
						[ngStyle]="{'background-color': verification.adminInitials ? getAdminInitialsColor(verification.adminCognitoId) : ''}"
						[title]="verification.adminFullName || 'Unassigned'"
						(click)="openAdminList(verification)">
						<span *ngIf="verification.adminInitials">{{ verification.adminInitials }}</span>
						<img *ngIf="!verification.adminInitials" src="assets/human-figure.svg" alt="?" />
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<app-admin-assignees [verification]="currentVerification" [adminUsers]="adminUsers" [openAdminAssigneesModal]="openAdminAssigneesModal" (didCloseModal)="openAdminAssigneesModal = false"></app-admin-assignees>