import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { Title } from "@angular/platform-browser"
import { RelatedEntity } from "../@shared/models/related-entity"

@Component({
  selector: 'app-related-entities',
  templateUrl: './related-entities.component.html'
})
export class RelatedEntitiesComponent {

  payers: Payer[] = []
  form: UntypedFormGroup
  relatedEntities: RelatedEntity[] = []
  typeFilter: string = ""
  types: string[] = []
  payerFilter: string = ""

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Payer Routing")

    this.form = this.formBuilder.group({
      name: [null, []],
      phoneNumber: [null, []],
      type: [null, [Validators.required]],
      payerId: [null, []],
      option: [null, [Validators.required]],
      pattern: [null, []]
    })

    this.getRelatedEntities()

    this.payers = await this.adminService.getPayers()
  }

  get filteredRelatedEntities(): RelatedEntity[] {
    return this.relatedEntities.filter((relatedEntity) => {
      return (this.typeFilter == "" || this.typeFilter == relatedEntity.type) &&
        (this.payerFilter == "" || this.payerFilter == relatedEntity.payerId)
    })
  }

  async getRelatedEntities() {
    this.relatedEntities = await this.adminService.getRelatedEntities()
    this.types = Array.from(new Set(this.relatedEntities.map((relatedEntity) => {
      return relatedEntity.type
    })))
  }

  async didSubmit() {
    const option = this.form.value.option

    if (option == null) {
      return
    }

    let name: string|null = null
    let pattern: string|null = null
    let phoneNumber: string|null = null

    if (option == "benefits-related-entity") {
      name = this.form.value.name
      phoneNumber = this.form.value.phoneNumber
    } else if (option == "member-id") {
      pattern = this.form.value.pattern
    }

    const type = this.form.value.type
    const payerId = this.form.value.payerId

    if (!type) {
      return
    } else if (option == "benefits-related-entity" &&
      !name) {
      return
    } else if (option == "member-id" &&
      !pattern) {
      return
    }

    try {
      await this.adminService.createRelatedEntity(name, phoneNumber, type, payerId, pattern)
      alert("Related entity created")
      this.getRelatedEntities()
    } catch (error) {
      alert("Something went wrong")
    }
  }

  async didClickDeleteRelatedEntity(entity: any) {
    const relatedEntityId = entity.id

    if (!relatedEntityId) {
      return
    }

    const confirmed = confirm("Are you sure you want to delete this related entity?")

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deleteRelatedEntity(relatedEntityId)
      alert("Related entity deleted")
      this.getRelatedEntities()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }
}
