<div class="header">
	<h1>Groups</h1>
</div>
<table>
	<thead>
		<td>Group</td>
		<td>Name</td>
		<td>CPT Codes</td>
		<td>Documentation</td>
	</thead>
	<tbody>
		<tr *ngFor="let taxonomyCodeGroup of taxonomyCodeGroups">
			<td>{{ taxonomyCodeGroup.taxonomyCodeGroup }}</td>
			<td>{{ taxonomyCodeGroup.name }}</td>
			<td>{{ taxonomyCodeGroup.codes.join(", ") }}</td>
			<td>
				<a target="_blank" [href]="taxonomyCodeGroup.url">{{ taxonomyCodeGroup.url }}</a>
			</td>
		</tr>
	</tbody>
</table>
