import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  public dropdownLeft = '0px';
  public dropdownTop = '0px';
  public isShowing = false;
  public targetElement: any;

  constructor() { }

  open() {
    const clientRect = (event.target as Element).getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    this.dropdownLeft = `${clientRect.left + scrollX - 220}px`;
    this.dropdownTop = `${clientRect.bottom + scrollY}px`;
    this.isShowing = true;
    this.targetElement = event.target;
  }

  close() {
    this.isShowing = false;
  }

  checkOutsideClick(target: any) {
    if (this.isShowing && !this.targetElement.contains(target)) {
      this.close();
    }
  }
}
