export class RelatedEntity {

	constructor(
		public name: string,
		public type: string,
		public payerName: string,
		public payerId: string,
		public id: number,
		public phoneNumber: string,
		public pattern: string
	) {}

	static fromJson(data: any): RelatedEntity {
		return new RelatedEntity(
			data.name,
			data.type,
			data.payerName,
			data.payerId,
			data.id,
			data.phoneNumber,
			data.pattern
		)
	}
}
