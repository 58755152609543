import { Component, HostListener, Input, Output, EventEmitter } from '@angular/core'
import { AdminUser } from "../../models/admin-user"
import { Verification } from "../../models/verification"
import { AdminService } from "../../services/admin.service"
import { stringToColor } from "../../helpers/colors"

@Component({
  selector: 'app-admin-assignees',
  templateUrl: './admin-assignees.component.html'
})
export class AdminAssigneesComponent {

  @Input() adminUsers: AdminUser[] = []
  @Input() openAdminAssigneesModal = false
  @Input() verification: Verification|null = null
  @Output() didCloseModal = new EventEmitter<void>()

  private adminColors: { [key: string]: string } = {}

  constructor(
    private adminService: AdminService
  ) {}

  async selectAdmin(
    admin: AdminUser|null
  ) {
    if (!admin) {
      this.openAdminAssigneesModal = false
      this.didCloseModal.emit()
      return
    }

    const verification = this.verification

    try {
      await this.adminService.addAssigneeToVerification(verification.verificationId, admin.adminCognitoId);
      verification.adminCognitoId = admin.adminCognitoId;
      verification.adminFirstName = admin.firstName;
      verification.adminLastName = admin.lastName;
    } catch (error) {
      alert("Something went wrong with assigning admin to verification. Please try again.")
    }
    finally {
      this.openAdminAssigneesModal = false
      this.didCloseModal.emit()
    }
  }

  getAdminInitialsColor(adminCognitoId: string): string {
    if (!adminCognitoId) {
      return '#E6E6E6';
    }
    if (!this.adminColors[adminCognitoId]) {
      let color = stringToColor(adminCognitoId);
      this.adminColors[adminCognitoId] = color;
    }
    return this.adminColors[adminCognitoId];
  }
}
