<div class="header">
	<h1>Edit Payer</h1>
</div>
<div>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<label>Name</label>
		<input type="text" formControlName="payerName" placeholder="Payer Name" />
		<label>Change Healthcare Payer ID</label>
		<input type="text" formControlName="changeHealthcarePayerId" placeholder="Change Healthcare Payer ID" />
		<label>Availity ID</label>
		<input type="text" formControlName="availityPayerId" placeholder="Availity ID" />
		<label>Serif ID</label>
		<input type="text" formControlName="serifPayerId" placeholder="Serif ID" />
		<label>Clearing House</label>
		<select formControlName="clearingHouseIdentifier" required>
			<option [value]="0">Change Healthcare</option>
			<option [value]="1">Availity</option>
		</select>
		<button>Submit</button>
	</form>
</div>