import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-specialties',
  templateUrl: './specialties.component.html'
})
export class SpecialtiesComponent {

  specialties: any[] = []

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Specialties")

    this.specialties = await this.adminService.getSpecialties()
  }
}
