export class PayerAdminAssignee {

	constructor(
		public payerId: string,
		public adminCognitoId: string,
		public payerName: string,
		public firstName: string,
		public lastName: string
	) {}

	get fullName(): string {
		if (!this.firstName) {
			return null
		} else if (!this.lastName) {
			return this.lastName
		}
		return `${this.firstName} ${this.lastName}`
	}

	static fromJson(data: any): PayerAdminAssignee {
		return new PayerAdminAssignee(
			data.payerId,
			data.adminCognitoId,
			data.payerName,
			data.firstName,
			data.lastName
		)
	}
}
