import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthUser } from '../@shared/models/auth-user';
import { AuthService, COGNITO_RESPONSES } from '../@shared/services/auth.service';
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.authService.logout()

    this.titleService.setTitle("Log In")

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
  }

  async onSubmit() {
    if (this.form.invalid) {
      return
    }

    const email = this.form.value.email

    try {
      await this.authService.login(
        email,
        this.form.value.password
      )

      this.router.navigate([''])
    } catch (error) {
      if (error == COGNITO_RESPONSES.mfaRequired) {
        this.router.navigate([
          "totp"
        ])
      } else if (error == COGNITO_RESPONSES.mfaSetup) {
        this.router.navigate([
          "authenticator"
        ],
        {
          queryParams: {
            email
          }
        })
      }
    }
  }
}
