export const stringToColor = (str: string): string => {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) + hash) + str.charCodeAt(i);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        // Ensure colors are dark (i.e., under the threshold of 128 for each RGB component)
        let value = (hash >> (i * 8)) & 0xFF;
        value = Math.floor(value / 2);
        color += value.toString(16).padStart(2, '0');
    }
    return color;
}