import { stringToColor } from '../../@shared/helpers/colors';

export class Note {

	constructor(
		public verificationId: string,
		public noteId: string,
		public firstName: string,
		public lastName: string, 
		public created: Date,
		public content: string
	) {}

	get fullName(): string {
		if (!this.firstName) {
			return null
		} else if (!this.lastName) {
			return this.lastName
		}
		return `${this.firstName} ${this.lastName}`
	}

	get initials(): string {
		return this.fullName?.split(" ").map(str => str[0]).join("")
	}

	get avatarBackgroundColor(): string {
		return stringToColor(this.fullName)
	}

	static fromJson(data: any): Note {
		return new Note(
			data.verificationId,
			data.noteId,
			data.firstName,
			data.lastName,
			new Date(data.created),
			data.content
		)
	}
}
