import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private confirmationSubject = new Subject<{ onConfirm: () => void, question: string, actionButton: string }>();

  getConfirmation() {
    return this.confirmationSubject.asObservable();
  }

  confirm(onConfirm: () => void, question: string, actionButton: string) {
    this.confirmationSubject.next({ onConfirm, question, actionButton });
  }
}
