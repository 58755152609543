<div class="header">
	<h1>Assignees</h1>
	<div>
		<a class="button" (click)="toggleAssignPayerForm()">Assign Payer</a>
	</div>
</div>
<div *ngIf="!showAssignPayerForm; else assignPayerForm">
	<table>
		<thead>
			<td>Payer</td>
			<td>Assignee</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let assignee of payerAdminAssignees">
				<td>{{ assignee.payerName }}</td>
				<td>{{ assignee.fullName }}</td>
				<td>
					<a class="button" (click)="deleteAssignee(assignee.payerId)">Delete</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<ng-template #assignPayerForm>
	<div class="row admin">
		<section>
			<form [formGroup]="form" (ngSubmit)="onSubmit()">
				<label>Payer</label>
				<select formControlName="payerId" required>
					<option value="">Select a payer</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }}</option>
				</select>
				<label>Admin</label>
				<select formControlName="adminCognitoId" required>
					<option value="">Select an admin</option>
					<option *ngFor="let adminUser of adminUsers" [value]="adminUser.adminCognitoId">{{
						adminUser.fullName }}
					</option>
				</select>
				<button>Assign Payer</button>
			</form>
			<button class="link-button-left no-margin" (click)="toggleAssignPayerForm()">Cancel</button>
		</section>
	</div>
</ng-template>