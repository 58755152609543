<div class="dashboard" [class.external]="isExternalUser">
	<div class="side-bar">
		<ul>
			<li class="logo"><a [routerLink]="['/']"><img src="assets/Logo Peach-White.svg"/></a></li>
			<li>
				<a [routerLink]="['/']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/home-smile.svg" />
					<img class="active-image" src="assets/home-smile-active.svg" />
					<span>Dashboard</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['/payers']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/organizations.svg" />
					<img class="active-image" src="assets/organizations-active.svg" />
					<span>Payers</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['/related-entities']" routerLinkActive="active-link"
					[routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Payer Routing</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['/customers']" routerLinkActive="active-link"
					[routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Customers</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['/groups']" routerLinkActive="active-link"
					[routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Groups</span>
				</a>
			</li>
		</ul>
	</div>
	<router-outlet></router-outlet>
</div>