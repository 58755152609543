<div class="header">
	<h1>{{ payer?.payerName }} - {{ taxonomyCode }}</h1>
</div>
<h2>Filters</h2>
<table>
	<thead>
		<td>Value</td>
		<td>Prioritise</td>
		<td>Exclude</td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let filter of filters">
			<td>{{ filter.value }}</td>
			<td>{{ filter.prioritise }}</td>
			<td>{{ filter.exclude }}</td>
			<td>
				<a class="button" (click)="didClickDeleteFilterValue(filter.id)">Delete</a>
			</td>
		</tr>
		<tr>
			<td>
				<input type="text" placeholder="Enter a value" [(ngModel)]="filterValue" />
			</td>
			<td>
				<select [(ngModel)]="prioritiseFilterValue">
					<option value="true">true</option>
					<option value="false">false</option>
				</select>
			</td>
			<td>
				<select [(ngModel)]="excludeFilterValue">
					<option value="true">true</option>
					<option value="false">false</option>
				</select>
			</td>
			<td>
				<a class="button" (click)="didClickAddFilterValue()">Add</a>
			</td>
		</tr>
	</tbody>
</table>
<div class="columns">
	<div>
		<h2>Request</h2>
		<table>
			<thead>
				<td>Service Type Code</td>
				<td>Priority</td>
				<td></td>
			</thead>
			<tbody>
				<tr *ngFor="let configuration of requestConfigurations">
					<td>{{ configuration.serviceTypeCode }}</td>
					<td>{{ configuration.priority }}</td>
					<td><a class="button" (click)="didClickDeleteConfiguration(configuration)">Delete</a></td>
				</tr>
				<tr>
					<td>
						<select [(ngModel)]="requestServiceTypeCode">
							<option [value]="null">Select a service type code</option>
							<option *ngFor="let serviceTypeCode of serviceTypeCodes" [value]="serviceTypeCode.code">
								{{ serviceTypeCode.code }} - {{ serviceTypeCode.name }}
							</option>
						</select>
					</td>
					<td></td>
					<td><a class="button" (click)="didClickAddServiceTypeCode(requestServiceTypeCode, '270')">Add</a></td>
				</tr>
			</tbody>
		</table>
	</div>
	<div>
		<h2>Response</h2>
		<table>
			<thead>
				<td>Service Type Code</td>
				<td>Priority</td>
				<td></td>
			</thead>
			<tbody>
				<tr *ngFor="let configuration of responseConfigurations">
					<td>{{ configuration.serviceTypeCode }}</td>
					<td>{{ configuration.priority }}</td>
					<td><a class="button" (click)="didClickDeleteConfiguration(configuration)">Delete</a></td>
				</tr>
				<tr>
					<td>
						<select [(ngModel)]="responseServiceTypeCode">
							<option [value]="null">Select a service type code</option>
							<option *ngFor="let serviceTypeCode of serviceTypeCodes" [value]="serviceTypeCode.code">
								{{ serviceTypeCode.code }} - {{ serviceTypeCode.name }}
							</option>
						</select>
					</td>
					<td></td>
					<td><a class="button" (click)="didClickAddServiceTypeCode(responseServiceTypeCode, '271')">Add</a></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<h2>Analysis</h2>
<table>
	<thead>
		<td>Value</td>
		<td></td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let serviceTypeCode of analysis.serviceTypeCodes">
			<td>{{ serviceTypeCode }}</td>
			<td></td>
			<td>
				<a class="button" (click)="didClickDeleteAnalysisServiceTypeCode(serviceTypeCode)">Remove</a>
			</td>
		</tr>
		<tr *ngFor="let filter of analysis.filters">
			<td>{{ filter }}</td>
			<td></td>
			<td>
				<a class="button" (click)="didClickDeleteAnalysisFilterValue(filter)">Remove</a>
			</td>
		</tr>
		<tr>
			<td>
				<input type="text" placeholder="Enter a value" [(ngModel)]="analysisValue" />
			</td>
			<td>
				<select [(ngModel)]="analysisValueType">
					<option value="serviceTypeCode">Service Type Code</option>
					<option value="filterValue">Filter</option>
				</select>
			</td>
			<td>
				<a class="button" (click)="didClickAddAnalysisValue()">Add</a>
			</td>
		</tr>
	</tbody>
</table>
<a class="button" (click)="didClickSubmitAnalysis()">Submit</a>