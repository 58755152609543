import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html'
})
export class SpecialtyComponent {

  specialty: any|null = null
  models: any[] = []
  codes: any[] = []

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Specialties")

    const specialtyId = this.route.snapshot.paramMap.get("id")

    const res = await this.adminService.getSpecialty(specialtyId)

    this.specialty = res.specialty
    this.models = res.models || []
    this.codes = res.codes || []
  }
}
