import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { ConfigurationGroup } from "../@shared/models/configuration-group"
import { Configuration } from "../@shared/models/configuration"
import { ActivatedRoute } from '@angular/router'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SERVICE_TYPE_CODES } from "../@shared/constants/data"
import { Title } from "@angular/platform-browser"
import { STATES } from "../@shared/constants/data"

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html'
})
export class PayerComponent {

  payerId: string|null = null
  payer: Payer|null = null
  relatedPayerForm: UntypedFormGroup
  payers: Payer[] = []
  serviceTypeCodes: { code: string, name: string}[] = SERVICE_TYPE_CODES
  relatedPayers: any[] = []
  taxonomyCodeGroups: any[] = []
  aliasPayerId: string|null = null
  state: string|null = ""
  states: string[] = STATES

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.payerId = this.route.snapshot.paramMap.get("id")

    this.getPayer()

    this.relatedPayerForm = this.formBuilder.group({
      relatedPayerId: [null, [Validators.required]],
      taxonomyCodeGroup: [null, [Validators.required]]
    })

    const data = await this.adminService.getData()

    this.taxonomyCodeGroups = data.taxonomyCodeGroups

    this.payers = await this.adminService.getPayers()

    this.relatedPayers = await this.adminService.getRelatedPayers(this.payerId)
  }

  async getPayer() {
    this.payer = await this.adminService.getPayer(this.payerId)
    this.titleService.setTitle(this.payer.payerName)
  }

  async didSubmitCreateRelatedPayerForm() {
    const payerId = this.payerId
    const relatedPayerId = this.relatedPayerForm.value.relatedPayerId
    const taxonomyCodeGroup = this.relatedPayerForm.value.taxonomyCodeGroup

    if (!payerId || !relatedPayerId || !taxonomyCodeGroup) {
      return
    }

    this.relatedPayerForm.reset()

    try {
      await this.adminService.createRelatedPayer(payerId, relatedPayerId, taxonomyCodeGroup)
      this.relatedPayers = await this.adminService.getRelatedPayers(payerId)
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickDeleteRelatedPayer(id: number) {
    const payerId = this.payerId

    if (!id || !payerId) {
      return
    }

    const confirmed = confirm(`Are you sure you want to delete this related payer?`)

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deleteRelatedPayer(payerId, id)
      this.relatedPayers = await this.adminService.getRelatedPayers(payerId)
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickDeleteState(
    state: string
  ) {
    const payerId = this.payer.payerId

    if (
      !payerId ||
      !state
    ) {
      return
    }

    try {
      await this.adminService.deleteState(
        payerId,
        state
      )
      this.getPayer()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickAddPayerAlias() {
    if (
      !this.payer ||
      !this.payer.payerId ||
      !this.aliasPayerId
    ) {
      return
    }

    try {
      await this.adminService.createAliasPayerID(
        this.payer.payerId,
        this.aliasPayerId
      )

      this.aliasPayerId = null

      this.getPayer()
    } catch (error: any) {
      if (error.error.error) {
        alert(error.error.error)
      } else {
        alert("Something went wrong")
      }
    }
  }

  async didClickAddState() {
    if (
      !this.payer ||
      !this.payer.payerId ||
      !this.state
    ) {
      return
    }

    try {
      await this.adminService.createPayerState(
        this.payer.payerId,
        this.state
      )

      this.state = ""

      this.getPayer()
    } catch (error: any) {
      if (error.error.error) {
        alert(error.error.error)
      } else {
        alert("Something went wrong")
      }
    }
  }

  async didClickRetryVerifications() {
    const payerName = this.payer.payerName
    const payerId = this.payer.payerId

    if (!payerName || !payerId) {
      return
    }

    const confirmed = confirm(`Are you sure you want to retry all pending verifications for ${payerName}?`)

    if (!confirmed) {
      return
    }

    try {
      const res = await this.adminService.retryPendingVerifications(payerId)
      console.log(res)
      alert(`${res.length} verifications retried`)
    } catch (error: any) {
      alert("Something went wrong")
    }
  }
}
