<div class="header">
	<h1>Customer</h1>
</div>

<table>
	<thead>
		<td>Name</td>
		<td>Created</td>
	</thead>
	<tbody>
		<tr>
			<td>{{ customer.name }}</td>
			<td>{{ customer.timestamp| date: 'MM/dd/yyyy' }}</td>
		</tr>
	</tbody>
</table>

<table>
	<thead>
		<td>Email Address</td>
		<td>Role</td>
	</thead>
	<tbody>
		<tr *ngFor="let user of users">
			<td>{{ user.emailAddress }}</td>
			<td>{{ user.role }}</td>
		</tr>
	</tbody>
</table>
