export class Customer {
	constructor(
		public customerId: string,
		public name: string | null,
		public timestamp: Date,
		public priceEstimate: number | null,
		public npi: string | null,
		public organizationName: string | null,
		public bluesPayerId: string | null,
		public taxId: string | null,
		public category: string | null,
		public refreshInterval: number,
		public description: string | null
	) { }

	static fromJson(data: any): Customer {
		return new Customer(
			data.customerId,
			data.name,
			data.timestamp,
			data.priceEstimate,
			data.npi,
			data.organizationName,
			data.bluesPayerId,
			data.taxId,
			data.category,
			data.refreshInterval,
			data.description
		);
	}
}
