export class VerificationCarveOut {

	constructor(
		public payerId: string,
		public payerName: string
	) {}

	static fromJson(data: any|null): VerificationCarveOut|null {
		if (!data) {
			return null
		}

		return new VerificationCarveOut(
			data.payerId,
			data.payerName
		)
	}
}
