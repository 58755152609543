import { Verification } from "./verification"

export class VerificationsResponse {

	constructor(
		public verificationCount: number,
		public pendingVerificationCount: number,
		public completeVerificationCount: number,
		public errorVerificationCount: number,
		public verifications: Verification[]) {}

	static fromJson(data: any): VerificationsResponse {
		return new VerificationsResponse(
			data.verificationCount,
			data.pendingVerificationCount,
			data.completeVerificationCount,
			data.errorVerificationCount,
			data.verifications.map((verification: any) => {
				return Verification.fromJson(verification)
			})
		)
	}
}