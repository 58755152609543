export const parseDate = (dateString: string): Date => {
  const parts = dateString.split("/");
  if (parts.length === 3) {
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
      return new Date(year, month, day);
    }
  }
  throw new Error("Invalid date format. Please use MM/DD/YYYY");
};
