<div class="header">
	<h1>Create Customer</h1>
</div>
<div class="admin">
	<section>
		<form [formGroup]="form" (ngSubmit)="didClickCreateSubmit()">
			<label>Email Address</label>
			<input type="text" formControlName="emailAddress" required placeholder="Enter email address">
			<label>Customer Name</label>
			<input type="text" formControlName="customerName" required placeholder="Enter customer name">
			<label>Quota</label>
			<p>This is an optional field that sets the maximum number of verifications that customer can create. This should be used for pilot customers.</p>
			<input type="number" formControlName="maxProductionVerificationCount" required placeholder="Enter quota">
			<button>Submit</button>
		</form>
		<table>
			<thead>
				<tr>
					<td>Email Address</td>
					<td>Password</td>
					<td>Production API Key</td>
					<td>Sandbox API Key</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{{ emailAddress }}</td>
					<td>{{ password }}</td>
					<td>{{ productionApiKey }}</td>
					<td>{{ sandboxApiKey }}</td>
				</tr>
			</tbody>
		</table>
		<p>Make sure to copy the password and API keys after creating the customer, they won't be available after you leave this page.</p>
	</section>
</div>
