<div class="header">
	<h1>Customers</h1>
	<div>
		<a class="button" [routerLink]="['/create-customer']">Create Customer</a>
	</div>
</div>
<div class="payers">
	<table>
		<thead>
			<td>Name</td>
			<td>Created</td>
		</thead>
		<tbody *ngFor="let customer of customers">
			<tr>
				<td>
					<a [routerLink]="['/customers', customer.customerId]">{{ customer.name }}</a>
				</td>
				<td>{{ customer.timestamp| date: 'MM/dd/yyyy' }}</td>
			</tr>
		</tbody>
	</table>
</div>